
import styles from './AlertHistoryPage.module.scss'
import {useEffect, useState} from "react";
import {perDS} from "../../datasource/WebDS";
import {AlertBasicDetails, AlertDetails, ComponentBasicDetails} from "@perentie/common";
import BasicAlertCard from "../../components/BasicAlertCard/BasicAlertCard";
import {useNavigate} from "react-router-dom";

function AlertHistoryPage(){

  const [recent, setRecent] = useState<AlertBasicDetails[]>([])
  const [comps, setComps] = useState<ComponentBasicDetails[]>([])
  const [compHist, setCompHist] = useState<AlertBasicDetails[]>([])
  const [selected, setSelected] = useState('none');
  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([perDS.userGetAllComponents(),perDS.getRecentAlerts(8)]).then(res => {
      setComps(res[0]);
      const recSorted = res[1].sort((a,b) => {
        return a.ended == null && b.ended == null ? b.ended!.getTime() - a.ended!.getTime() : 1;
      })
      setRecent(recSorted)
    }).catch( reason => {
      console.log(' all failed', reason);
    });
  }, []);

  const alertClick = (alert: AlertDetails) => {
    navigate('/alert/'+alert.objectId);
  }

  const recentCards = recent.map( deets => {
    return <BasicAlertCard key={deets.objectId} details={deets} showState={false} onClick={alertClick}/>
  });

  const options = comps
    .sort((a, b) => {
        return a.name == null && b.name == null ? 1 : a.name!.localeCompare(b.name!);
    })
    .map( c => {
      console.log(c);
    return <option value={c.objectId}>{c.name}</option>
  })

  const compAlerts = compHist.map( al => {
    return <BasicAlertCard key={al.objectId} details={al} showState={false} onClick={alertClick}/>
  });

  const optChanged = (item: any) => {
    setSelected(item.target.value);
    if (item.target.value === 'none'){
      setCompHist([]);
    } else {
      perDS.getComponentAlertHistory(item.target.value).then(res => {
        res = res.sort((a,b) => {
          return a.ended != null && b.ended != null ? b.ended!.getTime() - a.ended!.getTime() : 1;
        })
        setCompHist(res);
      }).catch( reason => {
        console.log(item.target.value + ' history fetch failed', reason);
      });
    }
  }



  return (
    <div className={[styles['content'], styles['contentFlex']].join(' ')}>
      <div>
        <h3>Last 8 Alerts For All Components</h3>
        {recent.length == 0 ? <em>None</em> : recentCards}
      </div>
      <div>
          <h3>For Specific Component</h3>
          <select onChange={optChanged} value={selected}>
            <option value={'none'}>Select Component..</option>
            {options}
          </select>
        {compAlerts.length == 0 ? <></> : compAlerts}
      </div>
    </div>
  )
}

export default AlertHistoryPage;
