import styles from './AlertDetailsPage.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {Fragment, useEffect, useState} from 'react';
import {perDS} from '../../datasource/WebDS';
import {AccessUtil, ActiveEventDetails, AlertDetails, EventLogItem, UserBasicDetails} from '@perentie/common';
import {Button, Cell, Label, Modal, Row, Table} from '@systemic-design-framework/react';
import {durationBetween} from '@perentie/common';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/authenticationSlice';

function AlertDetailsPage(){
  const navigate = useNavigate();
  const {alertId} = useParams();
  const [details, setDetails] = useState<AlertDetails>();
  const [summary, setSummary] = useState<ActiveEventDetails[]>([]);
  const [userOpts, setUserOpts] = useState<UserBasicDetails[]>([]);
  const [showAssign, setShowAssign] = useState(false);

  const [selectedUser, setSelectedUser] = useState<string>();
  const [saving, setSaving] = useState(false);

  const [comments, setComments] = useState<EventLogItem[]>([]);
  const [comment, setComment] = useState<string>('');
  const [showComment, setShowComment] = useState(false);
  const [addedComment, setAddedComment] = useState(false);
  const [eventLog, setEventLog] = useState<EventLogItem[]>([]);

  const currentUser = useSelector(selectUser);
  const accessUtil = new AccessUtil(currentUser);
  const canAssign = accessUtil.isSystemAdmin() || accessUtil.hasRoleType('CLIENT_ADMIN') || accessUtil.hasRoleType('RESPONDER');

  useEffect(() => {
    Promise.all([perDS.getAlertDetails(alertId!), perDS.getAlertEventSummary(alertId!)]).then( res => {
      const ds = res[0];
      ds.eventLog = ds.eventLog!.reverse();
      setDetails(res[0]);
      const cmts = ds.eventLog.filter(item => item.type === "COMMENT");
      setComments(cmts);
      setSummary(res[1]);
    }).catch(reason => {
      console.log(' catch', reason);
    })
    // @ts-ignore
    perDS.getUserHandlingOptions(alertId, "ASSIGN").then( res => {
      console.log(' handling options: ', res);
      setUserOpts(res);
    }).catch( reason => {
      console.log(' handling options fail', reason);
    })
  }, []);

  const selectOpts = userOpts.map( user => {
    return (
      <option value={user.objectId}>{user.username}</option>
    )
  })

  const eventSummary = summary.map( (evt, index) => {
    let classes = [styles['count']]
    if (evt.resolvedTime == null){
      classes.push(styles['countActive']);
    }else{
      classes.push(styles['countEnded']);
    }
    return (
      <div key={index + ' ' + evt.objectId}>
        <span className={classes.join(' ')}>{evt.messageCount}</span> {evt.lastMessage?.title}
      </div>
    )
  });

  const closeAlert = (item: AlertDetails) => {
    if (confirm('Are you sure you want to close this alert?\n\nThis cannot be undone.')) {
      perDS.closeAlert(item.objectId!).then(res => {
        navigate('/alerts');
      });
    }
  }

  const alertHandle = (type: string) => {
    switch(type) {
      case 'handle':
        perDS.handleAlert(details?.objectId!)
        .then(res => {
          setEventLog([]);
          setDetails(res);
        })
        .catch(err => {
          console.error('assignAlert fail ', err);
        });
        break;
      case 'unhandle':
        perDS.unHandleAlert(details?.objectId!)
        .then(res => {
          setEventLog([]);
          setDetails(res);
        })
        .catch(err => {
          console.error('unHandleAlert fail ', err);
        });
        break;
    }
  }

  const showAssignClick = () => {
    setShowAssign(true);
  }
  const selectChange = (val: any) => {
    console.log(' select change', val.target.value);
    setSelectedUser(val.target.value);
  }

  const confirmAssign = (action: string) => {
    if (action === 'Assign'){
      setSaving(true);
      perDS.assignAlert(alertId!, selectedUser!).then( res => {
        res.eventLog = res.eventLog?.reverse();
        setDetails(res);
        setSaving(false);
        setShowAssign(false);
      }).catch( reason => {
        setSaving(false);
        console.log('assignAlert fail ', reason);
        window.alert("Assigning user failed " + reason);
      })
    }else {
      setShowAssign(false);
    }
  }

  const showCommentClick = () => {
    setShowComment(true);
  }
  const showCommentClose = () => {
    setComment('');
    setShowComment(false);
  }

  const confirmComment = (action: string) => {
    if (action === 'Add'){
      setSaving(true);
      perDS.addCommentToAlert(alertId!, comment!).then(res => {
        setDetails(res);
        setSaving(false);
        setShowComment(false);
        setAddedComment(true);
      }).catch(err => {
        setSaving(false);
        console.error('addComment fail ', err);
        window.alert("Adding comment failed " + err);
      })
    }else {
      showCommentClose();
    }
  }

  const handleNewComment = (e: any) => {
    const { value } = e?.target;
    setComment(value);
  }

  const sortEventsNewestFirst = (arr: EventLogItem[]) => {
    return arr.sort((a, b) => new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime());
  }

  useEffect(() => {
    if (details) {
      setEventLog(sortEventsNewestFirst(details?.eventLog!));

      const cmts = sortEventsNewestFirst(details?.eventLog?.filter(item => item.type === "COMMENT")!);
      setComments(cmts!);

      setAddedComment(false);
    }
  }, [details])


  const renderItem = (item: EventLogItem, index: number) =>{
    return (
      <Row key={item.objectId} striped={index % 2 !=0}>
        <Cell>{item.timestamp?.toLocaleString()}</Cell>
        <Cell>{item.type}{(item.type === "EVENT" ? ' - ' + item.tag : '')}</Cell>
        <Cell>{item.title}</Cell>
        <Cell>{item.type != 'EVENT' ? item.tag : ''}</Cell>
      </Row>
    )
  }

  const TableHeader = () => {
    return (
      <Row>
        <Cell>Time</Cell>
        <Cell>Type</Cell>
        <Cell>Info</Cell>
        <Cell>Component State</Cell>
      </Row>
    );
  };

  const end = details?.ended == null ? new Date()  : details.ended!;
  const dur = durationBetween(details?.created, end);

  const CommentList = () => {
    return comments.map( item => {
      // @ts-ignore
      const user = details?.actionUsers[item.objectId];
      const title = user != null ? user.username : 'Unknown User';
      return (
        <div key={item.objectId} className={styles["comment"]}>
          <div>{title} {item.timestamp?.toLocaleString()}</div>
          <div className={styles["description"]}>{item.description}</div>
          <div></div>
        </div>
      )
    })
  }

  return (
    <div className={styles['content']}>
      <div style={{display:'grid', gridTemplateColumns: '1fr 1fr', columnGap:16}}>
        <div>
          <div className={styles['alertDetails']}>
            <div className={styles['header']}>
              <span>ALERT DETAILS</span>
              <Button size={'s'} title={'Close Alert'} onClick={() => closeAlert(details!)}/>
            </div>
            <dl>
              <dt></dt>
              <dd>
                <div style={{display: 'flex', gap: '6rem'}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    {details?.status} &nbsp;&nbsp; {details?.status != 'CLOSED' ? <Button title={"Assign"} size={"s"} onClick={showAssignClick}/> : []}
                  </div>
                  <div>
                    {details?.handlingUser?.objectId == currentUser.objectId ? (
                      // Current user is handling, they can Unhandle
                      <Button title={"Unhandle"} size={"s"} onClick={() => alertHandle('unhandle')}/>
                    ) : (
                      // Current user is not handling, check if they have permission to assign, then show Handle
                      canAssign ? (
                        <Button title={"Handle"} size={"s"} onClick={() => alertHandle('handle')}/>
                      ) : (
                        []
                      )
                    )}
                  </div>
                </div>
              </dd>
              <dt>Component:</dt>
              <dd>{details?.componentName}</dd>
              <dt>Start | End:</dt>
              <dd>{details?.created?.toLocaleString()} | {details?.ended == null ? '-' : details.ended.toLocaleString()}</dd>
              <dt>Duration:</dt>
              <dd>{dur == null ? '-' : dur.roughDuration()}</dd>
              <dt>Rule Trigger:</dt>
              <dd>{details?.lastRule?.name}</dd>
              <dt>Agent:</dt>
              <dd>{details?.agentName}</dd>
              <dt>Handled by:</dt>
              <dd>{details?.handlingUser == null ? '-' : details.handlingUser.username}</dd>
              <dt>Event Groups:</dt>
              <dd><div>{eventSummary}</div></dd>
            </dl>
          </div>
          <div>
            <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', gap: '32px'}}>
              <h3>Comments</h3>
              <Button title='Add Comment' size='s' onClick={showCommentClick}/>
            </div>
            {CommentList()}
          </div>
        </div>
        <div>
          <h3>Event Log</h3>
          {eventLog.length > 0 ? (
            <Fragment>
              {addedComment == false ? (
                <Table data={eventLog} renderItem={renderItem} HeaderComponent={<TableHeader />} border />
              ) : (
                []
              )}
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
        </div>
      </div>
      <Modal title="Assign Alert To User" onClose={() => setShowAssign(false)} visible={showAssign} actions={['Assign', 'Cancel']} actionReducer={confirmAssign}>
        <label htmlFor={'userSelect'}>Users</label>
        <select id={'userSelect'} onChange={selectChange} value={selectedUser}>
          <option>Select User...</option>
          {selectOpts}
        </select>
        {saving ? <p>Saving...</p> : []}

      </Modal>

      <Modal title="New Comment" onClose={showCommentClose} visible={showComment} actions={['Add', 'Cancel']} actionReducer={confirmComment}>
        {saving ? (
          <p>Saving...</p>
        ) : (
          <Fragment>
            <Label text='Comment'></Label>
            <textarea rows={1} className={styles['textarea']} onChange={(e) => (handleNewComment(e))} value={comment}></textarea>
          </Fragment>
        )}
      </Modal>
    </div>
  )
}

export default AlertDetailsPage;
