import styles from './ManageComponentsPage.module.scss'
import {useEffect, useState} from "react";
import {
  ComponentDetails, EventLogItem,
  NotifyChainDetails,
  NotifyChainRequest,
  StateHistoryDetails
} from "@perentie/common";
import {perDS} from "../../datasource/WebDS";
import NotifyChainComponent from "./NotifyChainComponent";
import {Button, Cell, Row, Table} from "@systemic-design-framework/react";
import StateHistoryComponent from "./StateHistoryComponent";
import StateText from "../../components/StateText/StateText";

interface ComponentCardProps {
  details: ComponentDetails;
}
function ComponentCard(props:ComponentCardProps){
  const {details} = props;
  return (
    <div className={styles['compDetails']}>
      <span>COMPONENT DETAILS</span>
      <dl>
        <dt>Name:</dt>
        <dd>{details.name}</dd>
        <dt>State:</dt>
        <dd><StateText>{details.state}</StateText></dd>
        <dt>Description:</dt>
        <dd>{details.description}</dd>
        <dt>Sticky Alerts:</dt>
        <dd>{details.stickyAlerts + ''}</dd>
        <dt>Identifier:</dt>
        <dd>{details.systemIdentifier}</dd>
        <dt>Agent:</dt>
        <dd>{details.agent?.name} ({details.agent?.objectId}) [{details.agent?.type}]</dd>
      </dl>
    </div>
  )
}

function ManageComponentsPage() {

  const [comps, setComps] = useState<ComponentDetails[]>([])
  const [compMap, setCompMap] = useState<Map<string, ComponentDetails>>(new Map());
  const [selectedOption, setSelectedOption] = useState('none');
  const [compDetails, setCompDetails] = useState<ComponentDetails | null | undefined>(null);
  const [notifyChain, setNotifyChain] = useState<NotifyChainDetails | null | undefined>(null);
  const [req, setReq] = useState<NotifyChainRequest | null>(null);
  const [stateHistory, setStateHistory] = useState<StateHistoryDetails[]>([]);
  const [events, setEvents] = useState<EventLogItem[]>([]);

  useEffect(() => {
    perDS.userGetAllComponents().then(res => {
      let nextMap = new Map<string,ComponentDetails>;
      res.forEach(cd => nextMap.set(cd.objectId!, cd));
      setComps(res);
      setCompMap(nextMap);
    }).catch( reason => {
      console.log(' getAllComponents failed', reason);
    });
  }, []);

  const optChanged = (item: any) => {
    setSelectedOption(item.target.value);
    setReq(null);
    if (item.target.value === 'none'){
      setCompDetails(null);
      setStateHistory([]);
    }else{
      const compId = item.target.value;
      setCompDetails(compMap.get(compId));
      perDS.getComponentNotifyChain(compId).then( res => {
        setNotifyChain(res);
      }).catch( reason => {
        console.log(' get notifychain catch', reason);
      });
      perDS.getComponentStateHistory(compId).then( res => {
        console.log(' state history', res);
        setStateHistory(res);
      }).catch( reason => {
        console.log(' getComponentStateHistory catch', reason);
      });
      perDS.getEventsSinceLastAlert(compId).then(res => {
        setEvents(res);
      }).catch(reason => {
        console.log(' getEventsSinceLastAlert catch', reason);
      })
    }
  }

  const options = comps.sort((a, b) => {
         return a.name != null && b.name != null ? a.name.localeCompare(b.name) : 1;
     }).map(c => {
         return <option key={c.objectId} value={c.objectId}>{c.name}</option>
     })

  const historyItems = stateHistory.map( item => {
    return <StateHistoryComponent key={item.objectId} historyItem={item}/>;
  })

  const handleOnCallChange = (level:number, user: string, onCall: boolean) => {
    // console.log('handle oncall: ', user, onCall);
    const nextReq: NotifyChainRequest = {
      componentId: notifyChain?.componentId,
      links: notifyChain?.links
    };
    nextReq.links?.forEach(l => {
      if (l.onCallMap![user] != null) {
        l.onCallMap![user] = onCall;
      }
    })
    console.log(' nextReq', nextReq);
    setReq(nextReq);
  }

  const updateClick = () => {
    if (!!req){
      perDS.updateComponentNotifyChain(compDetails?.objectId!, req).then( res => {
        console.log(' update result', res);
        setReq( null);
        setNotifyChain(res);
      }).catch( reason => {
        console.log(' update failed', reason);
      });
    }
  }


  const renderItem = (item: EventLogItem, index: number) =>{
    return (
      <Row key={item.objectId} striped={index % 2 !=0}>
        <Cell>{item.timestamp?.toLocaleString()}</Cell>
        <Cell>{item.type}{(item.type === "EVENT" ? ' - ' + item.tag : '')}</Cell>
        <Cell>{item.title}</Cell>
      </Row>
    )
  }

  const TableHeader = () => {
    return (
      <Row>
        <Cell>Time</Cell>
        <Cell>Type</Cell>
        <Cell>Info</Cell>
      </Row>
    );
  };


  return (
    <div className={styles['content']}>
      {/*<Link style={{backgroundColor: '#6aad00',*/}
      {/*  padding: '6px 8px',*/}
      {/*  borderRadius: 4,*/}
      {/*  fontSize:12,*/}
      {/*  color: "white",*/}
      {/*  textDecoration: 'none'}} to={'/components/create'}>Add New</Link>*/}

      <div style={{display: 'flex', flexDirection: 'row', marginTop: 16, columnGap: 16}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '45%'}}>
          <div style={{display: 'block'}}>
            <label style={{marginRight: 8}}>Details for Component</label>
            <select onChange={optChanged} value={selectedOption}>
              <option value={'none'}>Select Component..</option>
              {options}
            </select>
          </div>
          <div style={{marginTop: 16}}>
            {compDetails != null ? <ComponentCard details={compDetails}/> : []}
          </div>
          <div style={{width: '100%', height: 1, backgroundColor: 'darkgray', margin: '8px 0 8px 0'}}></div>
          <div>
            <div style={{display: 'inline-flex', alignItems: 'center', columnGap: 16}}>
              <p><strong>Notify Chain</strong></p>
              <Button size={'s'} disabled={req == null} title={'Update'} onClick={updateClick}/>
            </div>
            <NotifyChainComponent changeUserOnCall={handleOnCallChange} chain={notifyChain}/>
          </div>
          {events.length > 0 ?
            <div>
            <h3>Events Since Last Alert</h3>
            <Table data={events.sort(
                (a,b) => {
                    return a.timestamp != null && b.timestamp != null ? b.timestamp!.getTime() - a.timestamp!.getTime() : 1;
                })
                } renderItem={renderItem}
                   HeaderComponent={<TableHeader/>} border/>
          </div>
            : []}
        </div>

        <div style={{width: '50%', overflowY: 'auto', display: 'flex', flexDirection: 'column', rowGap: 8}}>
          <p>Recent State History</p>
          {historyItems}
        </div>
      </div>

    </div>
  )
}

export default ManageComponentsPage;
